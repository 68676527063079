<template>
  <v-app
    class="primary"
    :style="`height: 100vh; ${carregando ? 'overflow: hidden' : ''}`"
    :key="erro"
  >
    <v-overlay :value="carregando" absolute style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-if="!erro">
      <v-main class="primary">
        <FormSatisfacao
          :ticket="ticket"
          @carregando="toogleLoading"
          @finalizado="finalizar"
          :dados="satisfacao"
          v-if="!finalizado"
          :idSatisfacao="tokenresultTicket"
        />
        <div
          v-else
          style="height: 100vh; width: 100vw"
          class="d-flex flex-column align-center justify-center"
        >
          <div
            class="d-flex justify-end pr-8 pr-sm-12"
            style="position: fixed; bottom: 24px; width: 100vw"
          >
            <img
              src="../assets/logo-obrigado.png"
              style="width: 40%; max-width: 200px"
            />
          </div>

          <h1 class="text-h2 text-sm-h1 text--white ma-8 text--center">
            Obrigado pelo seu tempo.
          </h1>
          <h1 class="text-h4 text-sm-h3 text--white ma-4 text--center">
            Sua contribuição é valiosa!
          </h1>
        </div>
      </v-main>
    </template>
    <template v-else>
      <ErrorSatisfacao :erros="erro"></ErrorSatisfacao>
    </template>
  </v-app>
</template>

<script>
import API from "../api/API";
import FormSatisfacao from "../components/FormSatisfacao.vue";
import ErrorSatisfacao from "../components/ErrorSatisfacao.vue";
import jwt from "jsonwebtoken";

export default {
  name: "FormularioPosVendas",

  components: {
    FormSatisfacao,
    ErrorSatisfacao,
  },

  data: () => ({
    carregando: false,
    finalizado: false,
    erro: null,
    timer: null,
    ticket: "",
    satisfacao: null,
    tokenresultTicket: null,
  }),
  async created() {
    try {
      const token = this.$route.params.ticketJwt;
      this.tokenresultTicket = jwt.verify(token, "GALIENERGIA").idSatisfacao;

      this.satisfacao = await API.buscarSatisfacao(this.tokenresultTicket);
      if (this.satisfacao && this.satisfacao.respondida) {
        throw new Error("Satisfação já respondida");
      }
      this.ticket = this.satisfacao.ticket
    } catch (e) {
      this.erro = e;
      console.log(e);
    }
    this.finalizado = false;
  },
  methods: {
    toogleLoading(value) {
      const vue = this;
      if (!value)
        this.timer = setInterval(() => {
          clearInterval(vue.timer);
          vue.carregando = false;
        }, 1000);
      else this.carregando = value;
    },
    finalizar() {
      this.finalizado = true;
    },
  },
};
</script>
