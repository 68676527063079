<template>
  <v-form v-model="valid" class="container">
    <h1 class="text-h1 text--white ma-8 text--center">
      Pesquisa Satisfação Gali
    </h1>
    <p class="text-h6 text--white ma-8 text--center">
      Agradecemos pelo contato com nosso setor de pós-vendas.<br />
      Sua opinião é muito importante para nós, por isso, solicitamos que
      preencha nosso formulário de satisfação do atendimento.
    </p>
    <v-row class="formulario">
      <v-col cols="12" md="6" class="text--center">
        <label for=""
          >O atendimento prestado atendeu às suas expectativas?</label
        >
        <v-radio-group
          v-model="$v.expectativas.$model"
          row
          size="20"
          style="margin-left: 30px"
        >
          <div class="flex-column" v-for="rating in 10" :key="rating">
            <v-radio :value="rating"></v-radio>
            <span style="margin-left: 2px" v-if="rating < 10"
              >0{{ rating }}</span
            >
            <span style="margin-left: 2px" v-else>{{ rating }}</span>
          </div>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6" class="text--center">
        <div style="display: flex; justify-content: center">
          <label for="">As interações cumpriram os prazos informados?</label>
        </div>
        <v-radio-group
          v-model="$v.atualizacoesCumpridas.$model"
          row
          style="margin-left: 30px"
        >
          <div class="flex-column" v-for="rating in 10" :key="rating">
            <v-radio :value="rating"></v-radio>
            <span style="margin-left: 2px" v-if="rating < 10"
              >0{{ rating }}</span
            >
            <span style="margin-left: 2px" v-else>{{ rating }}</span>
          </div>
        </v-radio-group>
        <!-- <v-select
          v-model="$v.atualizacoesCumpridas.$model"
          :items="['Sim', 'Não']"
          solo
          placeholder="atualizações"
        ></v-select> -->
      </v-col>

      <v-col cols="12" md="6" class="text--center">
        <label for="">Como você avalia a praticidade do sistema?</label>
        <v-radio-group
          v-model="$v.praticidadeSistema.$model"
          row
          style="margin-left: 30px"
        >
          <div class="flex-column" v-for="rating in 10" :key="rating">
            <v-radio :value="rating"></v-radio>
            <span style="margin-left: 2px" v-if="rating < 10"
              >0{{ rating }}</span
            >
            <span style="margin-left: 2px" v-else>{{ rating }}</span>
          </div>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6" class="text--center">
        <label for=""
          >Qual é o seu grau de satisfação em relação ao atendimento
          recebido?</label
        >
        <v-radio-group
          v-model="$v.avaliacao.$model"
          row
          style="margin-left: 30px"
        >
          <div class="flex-column" v-for="rating in 10" :key="rating">
            <v-radio :value="rating"></v-radio>
            <span style="margin-left: 2px" v-if="rating < 10"
              >0{{ rating }}</span
            >
            <span style="margin-left: 2px" v-else>{{ rating }}</span>
          </div>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <label for=""
          >Descreva como nosso atendimento e plataforma de acionamentos de
          garantia podem ser otimizados e melhorados</label
        >
        <v-textarea
          placeholder="descrição do atendimento"
          solo
          v-model.trim="$v.descricaoAtendimento.$model"
          :error-messages="descricaoAtendimentoErrors"
        ></v-textarea>
      </v-col>
      <v-row class="text--right">
        <v-btn color="secondary" @click="proximaPagina" class="mb-12 ml-4">
          Enviar Resultado
        </v-btn>
      </v-row>
    </v-row>

    <v-snackbar
      style="position: fixed"
      :timeout="5000"
      :value="err"
      absolute
      center
      shaped
      bottom
      elevation="12"
      color="red accent-2"
      app
    >
      Ocorreu um erro <strong>ao enviar</strong> o formulário.
      <br />
      Por favor, tente novamente mais tarde.
    </v-snackbar>
  </v-form>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import API from "../api/API";

export default {
  name: "InfoInicial",
  props: {
    dados: Object,
    idSatisfacao: String,
    ticket: String,
  },
  data() {
    return {
      show: true,
      descricaoAtendimento: "",
      atualizacoesCumpridas: null,
      expectativas: null,
      praticidadeSistema: null,
      avaliacao: null,
    };
  },
  validations: {
    descricaoAtendimento: {
      minLength: minLength(3),
      maxLength: maxLength(400),
    },
    praticidadeSistema: {
      required,
    },
    avaliacao: {
      required,
    },
    atualizacoesCumpridas: {
      required,
    },
    expectativas: {
      required,
    },
  },
  methods: {
    async proximaPagina() {
      if (this.submit()) {
        console.log("update");
        const resp = await API.enviarSatisfacao({
          descricaoAtendimento: this.descricaoAtendimento,
          praticidadeSistema: this.praticidadeSistema,
          atualizacoesCumpridas: this.atualizacoesCumpridas,
          expectativas: this.expectativas,
          avaliacao: this.avaliacao,
          ticket: this.ticket,
          SK: this.idSatisfacao,
        });
        this.$emit("finalizado", {
          status: true,
          satisfacao: resp,
        });
      }
    },
    submit() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
  computed: {
    // VALIDATIONS
    descricaoAtendimentoErrors() {
      const errors = [];
      if (!this.$v.descricaoAtendimento.$dirty) return errors;
      !this.$v.descricaoAtendimento.minLength &&
        errors.push("O campo deve conter pelo menos 3 caracteres ");
      !this.$v.descricaoAtendimento.maxLength &&
        errors.push("O campo deve conter no máximo 400 caracteres");
      return errors;
    },
    // praticidadeSistemaErrors() {
    //   const errors = [];
    //   if (!this.$v.praticidadeSistema.$dirty) return errors;
    //   !this.$v.praticidadeSistema.minLength &&
    //     errors.push("O campo deve conter pelo menos 3 caracteres ");
    //   !this.$v.praticidadeSistema.maxLength &&
    //     errors.push("O campo deve conter no máximo 50 caracteres");
    //   !this.$v.praticidadeSistema.required && errors.push("Campo obrigatório");
    //   return errors;
    // },
    // atualizacoesCumpridasErrors() {
    //   const errors = [];
    //   if (!this.$v.atualizacoesCumpridas.$dirty) return errors;
    //   !this.$v.atualizacoesCumpridas.minLength &&
    //     errors.push("O campo deve conter pelo menos 3 caracteres ");
    //   !this.$v.atualizacoesCumpridas.maxLength &&
    //     errors.push("O campo deve conter no máximo 50 caracteres");
    //   !this.$v.atualizacoesCumpridas.required &&
    //     errors.push("Campo obrigatório");
    //   return errors;
    // },
    // expectativasErrors() {
    //   const errors = [];
    //   if (!this.$v.expectativas.$dirty) return errors;
    //   !this.$v.expectativas.minLength &&
    //     errors.push("O campo deve conter pelo menos 3 caracteres ");
    //   !this.$v.expectativas.maxLength &&
    //     errors.push("O campo deve conter no máximo 50 caracteres");
    //   !this.$v.expectativas.required && errors.push("Campo obrigatório");
    //   return errors;
    // },
  },
};
</script>

<style lang="scss">
.v-application .ma-8 {
  margin: 32px !important;
}
.text--center {
  text-align: center;
}
.text--white {
  color: white;
}
.form-principal {
  display: flex;
  flex-direction: column;
}
.formulario {
  background: white;
  border-radius: 4px;
  padding: 24px;
  margin: 5px;
}
</style>
