<template>
  <v-app>
    <v-main class="primary">
      <div
        style="height: 100vh; width: 100vw"
        class="d-flex flex-column align-center justify-center"
      >
        <div
          class="d-flex justify-end pr-8 pr-sm-12"
          style="position: fixed; bottom: 24px; width: 100vw"
        >
          <img
            src="../assets/logo-obrigado.png"
            style="width: 40%; max-width: 200px"
          />
        </div>

        <h1 class="text-h2 text-sm-h1 text--white ma-8 text--center">
          {{ titulo }}
        </h1>
        <h2 class="text-h2 text-sm-h2 text--white ma-8 text--center">
          {{ subtitulo }}
        </h2>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ErrorSatisfacao",
  props: {
    erros: Object,
  },
  data: () => ({
    titulo: "",
    subtitulo: "",
  }),
  async created() {
    if (this.erros.message == "Satisfação já respondida") {
      this.titulo = "Esta pesquisa já foi respondida!";
      this.subtitulo = "Obrigado pela sua contribuição.";
    } else if (this.erros.message == "jwt expired") {
      this.titulo = "Esta pequisa expirou!";
      this.subtitulo = "Não é possível mais responder essa pesquisa de satisfação.";
    } else {
      this.titulo = "Ocorreu um erro com o link da sua pesquisa!";
      this.subtitulo = "Tente novamente mais tarde.";
    }
    console.log("erros", this.erros.message);
  },
};
</script>